/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'

import privacy_policy from '../privacy_policy.pdf'
import terms_of_service from '../terms_of_service.pdf'

import footerImage from '../images/OP_Logo_Main_Footer.svg'

const Layout = ({ children, mainClass }) => {
    return (
        <div id="main-page" className={mainClass}>
            <main>{children}</main>
            <footer>
                <div className="section-wrapper">
                    <div className="col-section col-no-margin">
                        <div className="col-xs-6">
                            <img src={footerImage} width="66" /> &reg;{' '}
                            <span id="copyright">| &copy; {new Date().getFullYear()}</span>
                        </div>

                        <div className="col-xs-6">
                            Site designed by <a href="https://malley.design/">Malley.Design</a>
                        </div>

                        <div className="col-xs-6" style={{ textAlign: 'right' }}>
                            <a href={privacy_policy}>Privacy Policy</a>
                        </div>

                        <div className="col-xs-6" style={{ textAlign: 'left' }}>
                            <a href={terms_of_service}>Terms Of Service</a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
